import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanProgramsGridRow";
// import HomeWorth from "../components/Repeating/HomeWorth";
import ValueProps from "../components/Repeating/ValueProps";
import FAQs from "../components/Repeating/FAQs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import benefit1 from "../images/3.0 Services/Refinance/Get a lower interest rate.svg";
import benefit2 from "../images/3.0 Services/Refinance/Lock in a lower interest rate.svg";
import benefit3 from "../images/3.0 Services/Refinance/Reduce your monthly payment.svg";
import benefit4 from "../images/3.0 Services/Refinance/Pay off your loan early.svg";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "What are the current rates?",
      answer: (
        <>
          <p>
            Pricing/interest rates change daily and, sometimes, multiple times a
            day. The best way to get a current rate is to speak with one of your
            mortgage loan originators by calling today.
          </p>
        </>
      ),
    },
    {
      question: "What’s it going to cost me?",
      answer: (
        <>
          <p>
            No matter what anyone tells you, all loans have a cost; however, you
            can finance the cost by getting either a bigger loan amount, a
            higher interest rate, or both.
          </p>
          <p>
            The cost will vary depending on your personal situation; however,
            wouldn’t you prefer to deal with a highly educated and experienced
            mortgage loan originator who is not only an attorney but an expert
            with financing?
          </p>
        </>
      ),
    },
    {
      question: "Can I get cash out?",
      answer: (
        <>
          <p>
            If you have sufficient equity in your home, you can get cash out.
          </p>
        </>
      ),
    },
    {
      question: "Do I qualify?",
      answer: (
        <>
          <p>
            The best way to find out if you qualify is to speak with one of your
            mortgage loan originators by calling today.
          </p>
        </>
      ),
    },
    {
      question: "How long does it take?",
      answer: (
        <>
          <p>
            Timing is a function of the type of loan you seek. Some loans can be
            closed in under three weeks. Reverse mortgages can take as long as
            six to ten weeks to close.
          </p>
        </>
      ),
    },
    {
      question:
        "Is a refinance loan a new mortgage, or is it attached to my current mortgage?",
      answer: (
        <>
          <p>
            A refinance is a completely new loan (either with your existing
            lender or a new lender). Your current loan is paid off, and you have
            a new loan that replaces it.
          </p>
        </>
      ),
    },
    {
      question:
        "What happens if market rates are higher than my current interest rate?",
      answer: (
        <>
          <p>
            If current interest rates exceed your existing mortgage, then you
            will want to explore whether extending your term or pulling out more
            cash based on your equity is right for you.
          </p>
          <p>
            Depending on your situation, your payment may increase but you may
            have more cash immediately available. Working with an attorney-owned
            and operated mortgage company will help you make the best decision
            for your situation.
          </p>
        </>
      ),
    },
    {
      question: "What are some other possibilities with my refinance?",
      answer: (
        <>
          <p>
            Besides the benefits of lower rates, lower monthly payments, and
            paying off your loan early, you can take advantage of extra money in
            your budget to make home improvements, save for retirement, or fund
            any other goal you may have.
          </p>
        </>
      ),
    },
    {
      question: "What is the first step to getting started?",
      answer: (
        <>
          <p>
            The first step to refinancing your home loan is checking to see if
            you qualify. As a reminder, this is what lenders like to see:
          </p>
          <ul className="styled-list-checkmark mb-6">
            <li>Your current mortgage loan is in good standing</li>
            <li>
              You have sufficient equity in your home (usually above 20%){" "}
            </li>
            <li>Your credit score is 620 or higher</li>
            <li>You have a moderate debt-to-income ratio</li>
          </ul>

          <p>
            If you think you would qualify, contact our team so we can show you
            the new and improved rates you can get.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Refinance Home Loans Orange County | Nikkael"
        description="Refinancing your home loan decreases your monthly payment by providing you with a new and improved mortgage. Contact us today to learn more!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-10 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Refinancing Your Home Loan</h1>
              <p>
                Ever thought your interest rate was too high? Want to decrease
                your monthly payment? Refinancing your home loan accomplishes
                this by providing you with a new and improved mortgage. Our team
                at Nikkael are experts on refinances and is here to help you
                each step of the way.
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 Services/Refinance/1.0 Refinance Loans.jpg"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-12 md:mb-16">
            <h2>The Advantages of Refinancing</h2>
          </header>

          <div className="grid gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit1} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">Get a lower interest rate</h3>
                <p className="mb-0">
                  Take advantage of changing market interest rates to access a
                  lower rate.
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit2} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">
                  Lock in a lower interest rate
                </h3>
                <p className="mb-0">
                  Your loan can be changed to a fixed rate, saving you thousands
                  over the course of your loan term.
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit3} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">
                  Reduce your monthly payment
                </h3>
                <p className="mb-0">
                  Usually a lower interest rate means lower payments, freeing up
                  money for improvement or retirement.
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit4} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">Pay off your loan early</h3>
                <p className="mb-0">
                  The lower monthly payments and interest rates give you the
                  ability to pay everything off before the term is over.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/3.0 Services/eligibility.jpg"
                loading="lazy"
                width={560}
              />
            </div>
            <div>
              <h2>Am I Eligible?</h2>
              <p>
                Refinancing your mortgage is the best way to get more favorable
                loan terms and save money. Here’s what you typically need to
                qualify:
              </p>
              <ul className="styled-list-checkmark mb-6">
                <li>Your current mortgage loan is in good standing</li>
                <li>Sufficient equity in your home (usually above 15-20%)</li>
                <li>A qualifying credit score (usually above 620)</li>
                <li>A moderate debt-to-income ratio</li>
              </ul>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
          </div>
        </div>
      </section>

      {/* <HomeWorth /> */}
      <FAQs heading="Common Questions About Refinancing" uniqueFaqs={faqs} />
      <ValueProps />
      <Testimonials />
      <LoanPrograms
        heading="You Might Also Be Interested In"
        hidePrograms={[1, 5]}
      />
      <CallToAction
        heading="Considering Refinancing?"
        text="We’re experts in finding the best refinance loans in California. Contact us today!"
      />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-refinance.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-refinance.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
